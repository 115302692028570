<template>
	<section class="domain_content">
		<!-- About Us -->
		<div class="sub-page">
			<h1>About Us</h1>
			<p>
        		Welcome to <a href="https://gbwhatsapk.net/">https://gbwhatsapk.net/</a>, your trusted destination for discovering innovative and reliable website that enhance your digital experience. Our mission is to provide users with the app that offer unique features. Whether you’re looking to boost productivity, stay connected, or explore new entertainment options, GBWhatsApp is here to guide you every step of the way. Your digital journey starts here!
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'Our goal is to provide you with a safe apk source. Our site also deserves trust.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  